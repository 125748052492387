import "./homepage.html";
import "../../assets/js/particles.js-master/particles.min.js";
import "./particle-config.js";
import "dragscroll";
import "waypoints/lib/noframework.waypoints.min.js";
import Odometer from "odometer";
import "odometer/themes/odometer-theme-default.css";
import ContactForm from "../components/contact-form/contact-form.js";
class Home {
  constructor() {
    this.initialize();
  }

  initialize() {
    document.querySelector(".main-loader").classList.add("hide");
    //document.querySelector('.home').classList.remove('hide');
    //document.querySelector('.home').classList.add('show-block');

    let elements = [];
    elements.push({
      e: document.querySelector(".content__skill--1"),
      class: "width-100",
    });
    elements.push({
      e: document.querySelector(".content__skill--2"),
      class: "width-80",
    });
    elements.push({
      e: document.querySelector(".content__skill--3"),
      class: "width-90",
    });
    elements.forEach((element) => {
      new Waypoint({
        element: element.e,
        handler: function (direction) {
          element.e.classList.add(element.class);
        },
        offset: "bottom-in-view",
      });
    });
    elements = [];
    elements.push({
      e: document.querySelector("#slide1-heading"),
      class: "fadeInUp",
    });
    elements.push({
      e: document.querySelector(".content__service--branding"),
      class: "fadeIn",
    });
    elements.push({
      e: document.querySelector(".content__service--social-media"),
      class: "fadeIn",
    });
    elements.push({
      e: document.querySelector(".content__service--adwords"),
      class: "fadeIn",
    });
    elements.push({
      e: document.querySelector(".content__service--content-marketing"),
      class: "fadeIn",
    });
    elements.push({
      e: document.querySelector(".content__service--analytics"),
      class: "fadeIn",
    });
    elements.push({
      e: document.querySelector(".content__service--website"),
      class: "fadeIn",
    });
    elements.push({
      e: document.querySelector(".content__client--1"),
      class: "fadeInRight",
    });
    elements.push({
      e: document.querySelector(".content__client--2"),
      class: "fadeInRight",
    });
    elements.push({
      e: document.querySelector(".content__client--3"),
      class: "fadeInRight",
    });
    elements.push({
      e: document.querySelector(".content__client--4"),
      class: "fadeInRight",
    });
    elements.push({
      e: document.querySelector(".content__client--5"),
      class: "fadeInRight",
    });
    elements.push({
      e: document.querySelector(".content__client--6"),
      class: "fadeInRight",
    });
    elements.push({
      e: document.querySelector(".content__client--7"),
      class: "fadeInRight",
    });
    elements.push({
      e: document.querySelector(".content__client--8"),
      class: "fadeInRight",
    });
    elements.push({
      e: document.querySelector(".content__client--9"),
      class: "fadeInRight",
    });
    elements.push({
      e: document.querySelector(".content__client--10"),
      class: "fadeInRight",
    });
    elements.push({
      e: document.querySelector(".content__client--11"),
      class: "fadeInRight",
    });
    elements.push({
      e: document.querySelector(".content__client--12"),
      class: "fadeInRight",
    });
    elements.push({
      e: document.querySelector(".content__client--13"),
      class: "fadeInRight",
    });
    elements.push({
      e: document.querySelector(".content__client--14"),
      class: "fadeInRight",
    });
    elements.push({
      e: document.querySelector(".content__client--15"),
      class: "fadeInRight",
    });
    elements.push({
      e: document.querySelector(".content__client--16"),
      class: "fadeInRight",
    });
    elements.push({
      e: document.querySelector(".content__client--17"),
      class: "fadeInRight",
    });
    elements.forEach((element) => {
      new Waypoint({
        element: element.e,
        handler: function (direction) {
          element.e.classList.add("animated");
          element.e.classList.add(element.class);
          element.e.classList.add("toAnimate");
        },
        offset: "80%",
      });
    });

    let el1 = document.querySelector("#number1");

    let od1 = new Odometer({
      el: el1,
      value: 0,

      // Any option (other than auto and selector) can be passed in here
      format: "",
      theme: "default",
    });

    od1.update(2);

    let el2 = document.querySelector("#number2");

    let od2 = new Odometer({
      el: el2,
      value: 0,

      // Any option (other than auto and selector) can be passed in here
      format: "",
      theme: "default",
    });

    let el3 = document.querySelector("#number3");

    let od3 = new Odometer({
      el: el3,
      value: 100000,

      // Any option (other than auto and selector) can be passed in here
      format: "",
      theme: "default",
    });

    new Waypoint({
      element: el1,
      handler: function (direction) {
        od1.update(2);
      },
      offset: "90%",
    });

    new Waypoint({
      element: el2,
      handler: function (direction) {
        od2.update(27);
      },
      offset: "90%",
    });

    new Waypoint({
      element: el3,
      handler: function (direction) {
        od3.update(275000);
      },
      offset: "90%",
    });

    if (document.querySelector(".contact-form")) {
      new ContactForm();
    }
  }
}

if (document.querySelector(".home")) {
  setTimeout((_) => new Home(), 2000);
}
