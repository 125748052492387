import "./refund.html";
import "dragscroll";
import "waypoints/lib/noframework.waypoints.min.js";

class Refund {
  constructor() {
    this.initialize();
  }

  initialize() {
    document.querySelector(".main-loader").classList.add("hide");
  }
}

if (document.querySelector(".refund")) {
  setTimeout((_) => new Refund(), 2000);
}
