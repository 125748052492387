import './about.html';
import 'dragscroll';
import 'waypoints/lib/noframework.waypoints.min.js';

class About {
    constructor() {
        this.initialize();
    }

    initialize() {
        document.querySelector('.main-loader').classList.add('hide');
    }
    
}

if (document.querySelector('.about')) {
    setTimeout(_ => new About(), 2000);   
}
