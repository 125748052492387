import './menu.html';
import 'waypoints/lib/noframework.waypoints.min.js';

class Menu {
    constructor() {
        this.initialize();
    }

    initialize() {
        let menuOpen = 0;
        let menu = document.querySelector('.menu');
        menu.addEventListener('mouseover', () => {
            menu.classList.add('expand');
            menuOpen = 1;
        });
        menu.addEventListener('mouseout', () => {
            menu.classList.remove('expand');
            menuOpen = 0;
        })
        let toggler = document.getElementById('menu-toggler');
        if (toggler) {
            toggler.addEventListener('click', () => {
                if (menuOpen) {
                    menu.classList.remove('expand');
                    menuOpen = 0;
                } else {
                    menu.classList.add('expand');
                    menuOpen = 1;
                }
            })
        }
    }
    
}

if (document.querySelector('.menu')) {
    new Menu();
}
