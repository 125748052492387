import './contact.html';
import 'waypoints/lib/noframework.waypoints.min.js';
import ContactForm from '../components/contact-form/contact-form.js';
class Contact {
    constructor() {
        this.initialize();
    }

    initialize() {
        document.querySelector('.main-loader').classList.add('hide');
        if (document.querySelector('.contact-form')) {
            new ContactForm();
        }
    }
    
}

if (document.querySelector('.contact')) {
    setTimeout(() => new Contact(), 2000);
}
