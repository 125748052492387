import './contact-form.html';
import 'waypoints/lib/noframework.waypoints.min.js';
import $ from 'jquery';
let DB_PATH = 'https://digilabsmedia.firebaseio.com/connect.json';

export default class {
    constructor() {
        this.initialize();
    }

    initialize() {
        document.getElementById("connect-form-submit").addEventListener("click", () => {
            let resWait = document.querySelector('.content__waiting--loading');
            let resElem = document.querySelector('.content__waiting--response');
            let name = document.getElementById('name');
            let email = document.getElementById('email');
            let phone = document.getElementById('phone');
            let message = document.getElementById('message');
            var note_name = name.value;
            var note_email = email.value;
            var note_phone = phone.value;
            var note_message = message.value;
    
            if(note_name && note_message) {
                resWait.classList.add("show");
                var data = {
                    note_name,
                    note_email,
                    note_phone,
                    note_message
                }
    
                $.ajax({
                url: DB_PATH,
                type: 'POST',
                data: JSON.stringify(data),
                contentType: 'text/plain',
                dataType: 'json',
                success: function(data) {
                    resElem.classList.add("show");
                    resWait.classList.remove("show");
                    setTimeout(() => {
                        resElem.classList.remove("show");
                        name.value = '';
                        email.value = '';
                        phone.value = '';
                        message.value = '';
                    }, 3000);
                },
                error: function(error) {
                    reject(error);
                }
                });
            }
        })
    }
}
