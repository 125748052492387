import "./termsAndConditions.html";
import "dragscroll";
import "waypoints/lib/noframework.waypoints.min.js";

class TermsAndConditions {
  constructor() {
    this.initialize();
  }

  initialize() {
    document.querySelector(".main-loader").classList.add("hide");
  }
}

if (document.querySelector(".terms-and-conditions")) {
  setTimeout((_) => new TermsAndConditions(), 2000);
}
